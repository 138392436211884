import React, { useState } from 'react';
import { useFormik } from 'formik';
import { TextField, Button, InputAdornment, IconButton, FormControl, Container, Box, CircularProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import './SignIn.css'
import { useNavigate } from 'react-router-dom';
import { logInAPIConfig } from '../API/apiConfig';
import axios from 'axios';

const Signupschema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Please enter your email'),
  password: Yup.string()
    .min(4, 'Password must be at least 7 characters')
    .required('Please enter a password'),
});

const SignIn = ({ setIsAuthenticated }) => {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true)



  const handleSubmit = (values) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('email', values.email)
    formData.append('password', values.password)
    axios.post(logInAPIConfig.logIn, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('access_token', response.data.accessToken);
          setIsAuthenticated(true);
          navigate('/operation');
          setLoading(false)

        } else {
          setLoading(false)
          alert('Email or password wrong')
        }
      })
      .catch((error) => {
        setLoading(false)
        alert('Something went wrong')
      });

  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Signupschema,
    onSubmit: handleSubmit,
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  if (loading) {
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </Container>
  }


  return (
    <div className='Backgroundall'>
      <div className='form'>
        <center>Login Here</center>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth style={{ marginBottom: "25px" }}>
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "25px" }}>
            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Button type="submit" variant="contained" color="primary" fullWidth className='button'>
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;