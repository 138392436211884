import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Operation from "./Components/Operation";
import SignIn from "./Components/SignIn";
import { logInAPIConfig } from "./API/apiConfig";
import axios from "axios";
import { Box, Container } from "@mui/material";
import CircularProgress, {
} from '@mui/material/CircularProgress';

const TokenChecker = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    const access_token = window.localStorage.getItem('access_token');
    if (access_token) {
      axios.get(logInAPIConfig.logInUsingToken, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setIsAuthenticated(true);
            navigate('/operation');
            setLoading(false)
          } else {
            setIsAuthenticated(false);
            navigate('/signin');
            setLoading(false)
          }
        })
        .catch(() => {
          setIsAuthenticated(false);
          navigate('/signin');
          setLoading(false)
        });
    } else {
      setIsAuthenticated(false);
      navigate('/signin');
      setLoading(false)
    }
  }, [navigate, setIsAuthenticated]);

  if (loading) {
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </Container>
  }

  return null;


};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  return (
    <Router>
      {/* <TokenChecker setIsAuthenticated={setIsAuthenticated} /> */}
      <Routes>
        <Route
          path="/signin"
          element={<SignIn setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route
          path="/"
          element={isAuthenticated ? <Operation /> : <Navigate to="/signin" />}
        />
        {/* <Route
          path="/"
          element={<Navigate to="/signin" />}
        /> */}
      </Routes>
    </Router>
  );
}

export default App;
